import { API_TOKEN_KEY, APP_ENV, ERC20_TOKENS_KEY } from '../config'

let tokens = undefined

class Emitter {
  onChange = () => {}
  change = (x) => {
    this.onChange(x)
  }
}

export const emitter = new Emitter()

export const signOutRemoveKeys = () => {
  ;[API_TOKEN_KEY, ERC20_TOKENS_KEY].forEach((key) => localStorage.removeItem(key))
}

export const getTokens = () => {
  const tokens = localStorage.getItem(ERC20_TOKENS_KEY)
  return JSON.parse(tokens)
}

export const setTokens = (newTokensObj) => {
  console.log('setTokens', newTokensObj)
  tokens = newTokensObj
  localStorage.setItem(ERC20_TOKENS_KEY, JSON.stringify(newTokensObj))
  emitter.change(tokens)
}

export const getChainByCoinType = (coinType) => {
  let res = APP_ENV === 'prod' ? 'mainnet' : 'ropsten'
  switch (coinType) {
    case 'ETH':
      break
    case 'ETC':
      res = 'etc'
      break
    default:
      if (coinType) res = getTokens()[coinType].chain
  }
  return res
}
