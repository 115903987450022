import React from 'react'
import Header from './Header'

const Layout = (props) => (
  <div
    style={{
      padding: 16,
    }}
  >
    <Header />
    <hr />
    {props.children}
  </div>
)

export default Layout
