import React, { useEffect, useState } from 'react'
import { HDKey } from '@scure/bip32'
import * as ledger from '../../../utils/wallet/hd/ledger'
import * as api from '../../../services/api'
import * as filecoinUtils from '../../../utils/wallet/hd/ledger/filecoin'
import { FIL_MAIN_WALLET_FULL_PATH, FIL_XPUB_KEY, FIL_USER_WALLET_BASE_PATH } from '../../../config'

const WALLET_KEY = FIL_XPUB_KEY
const BASE_XPUB_PATH = FIL_USER_WALLET_BASE_PATH

export const FilecoinConfig = () => {
  const [walletId, setWalletId] = useState(null)
  const [xpub, setXpub] = useState(localStorage.getItem(WALLET_KEY))
  const [show, setShow] = useState(false)
  const [isConnected, setIsConnected] = useState(false)

  useEffect(() => {
    ledger.isConnected().then((_isConnected) => setIsConnected(_isConnected))
  }, [])

  const getXpub = async () => {
    const xpub = prompt(`请输入xpub，path \`${BASE_XPUB_PATH}\``)
    try {
      // 验证 xpub 输入正确用
      HDKey.fromExtendedKey(xpub)
    } catch (e) {
      alert(`xpub 格式错误 ${e}`)
      return
    }
    setXpub(xpub)
    localStorage.setItem(WALLET_KEY, xpub)
  }

  const clearStorage = () => {
    localStorage.removeItem(WALLET_KEY)
    window.location.reload()
  }

  const saveWallet = async () => {
    try {
      const sendingAddr = await filecoinUtils
        .getProvider()
        .then((provider) => filecoinUtils.getAddress(provider)(FIL_MAIN_WALLET_FULL_PATH))
      console.log('send address', sendingAddr)
      const xpub = localStorage.getItem(WALLET_KEY)
      const res = await api.setWallet({
        coin: 'FIL',
        coinType: 'FIL',
        xpub,
        xpubPath: BASE_XPUB_PATH,
        testnet: false,
        sendingAddr,
      })
      setWalletId(res.id)
      alert('保存成功')
    } catch (e) {
      alert(`保存失败 ${e}`)
    }
  }

  const toggleShow = () => {
    setShow((show) => !show)
  }

  if (!show) {
    return <h2 onClick={toggleShow}>FIL Wallet (click to show)</h2>
  }

  return (
    <div>
      <h2 onClick={toggleShow}>Filecoin Wallet (click to hide)</h2>
      <div className={'bg-slate-200'}>
        <div>
          <h3>
            ledger:{' '}
            {isConnected ? (
              <span className={'text-green-500'}>已连接</span>
            ) : (
              <span className={'text-red-500'}>未连接</span>
            )}
          </h3>
          <button onClick={ledger.connect} className={'btn btn-sm'} disabled={isConnected}>
            连接
          </button>
        </div>
        <div>
          <p>
            使用说明：使用脚本获取 `{FIL_USER_WALLET_BASE_PATH}` xpub，点击 `set xpub key` 设置 xpub，点击 `save wallet`
            在 ledger 上确认
          </p>
          <p>wallet id: {walletId || 'wallet not saved'}</p>
          <button onClick={clearStorage} className={'btn btn-sm'}>
            clear storage cache
          </button>
          <button onClick={saveWallet} className={'btn btn-sm'}>
            save wallet
          </button>
        </div>
        <div>
          <p>wallet xpub: {xpub}</p>
          <button onClick={getXpub} className={'btn btn-sm'}>
            set xpub key
          </button>
        </div>
      </div>
    </div>
  )
}
