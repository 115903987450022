import React, { Component } from 'react'

import TxSendList from '../components/TxSendList'
import SignedList from '../components/SignedList'
import BalanceList from '../components/BalanceList'

class Main extends Component {
  refreshUnsigned = () => {
    this.unsigned && this.unsigned.refreshTx()
  }
  refreshSigned = () => {
    this.signed && this.signed.refreshTx()
  }

  render() {
    return (
      <div>
        <div className={'container'}>
          <BalanceList ref={(node) => (this.balance = node)} refreshUnsigned={this.refreshUnsigned} />
        </div>
        <div className="divider clear-both" />
        <div className={'container'}>
          <TxSendList ref={(node) => (this.unsigned = node)} refreshSigned={this.refreshSigned} />
        </div>
        <div className="divider clear-both" />
        <div className={'container'}>
          <SignedList ref={(node) => (this.signed = node)} />
        </div>
      </div>
    )
  }
}

export default Main
