import Fetch from '@amanooo/fetch'
import BigNumber from 'bignumber.js'
import { API_URL, TRX_MAIN_WALLET_FULL_PATH, TRX_MAIN_WALLET_KEY } from '../config'

const TronWeb = require('tronweb')
const fullNode = 'https://api.trongrid.io'
const tronWeb = new TronWeb(fullNode, fullNode, fullNode, '')

export const CONTRACTS = {
  // https://tronscan.org/#/contract/TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t/code
  USDTTRC20: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
  // https://tronscan.org/#/token20/TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8
  USDCTRC20: 'TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8',
}
export const TRON_FREEZE = {
  ENERGY: 'ENERGY',
  BANDWIDTH: 'BANDWIDTH',
}

class Tron {
  TRON_FREEZE = TRON_FREEZE

  async signTx(opts, callback) {
    const { collectPath, collectAddr, value, id, coinType } = opts
    const walletCollectingAddr = localStorage.getItem(TRX_MAIN_WALLET_KEY)

    if (!walletCollectingAddr) {
      callback('TRX_WALLET_SENDING_ADDR is NULL')
      return
    }

    let sendingAddrPath = TRX_MAIN_WALLET_FULL_PATH
    let sendingAddr = walletCollectingAddr

    if (collectPath != null) {
      sendingAddrPath = collectPath
      sendingAddr = collectAddr
    }

    console.log('sendingAddr %s', sendingAddr)
    console.log('sendingAddrPath %s', sendingAddrPath)
    console.log('opts.to %s', opts.to)
    let tradeobj
    if (opts.coinType === 'TRX') {
      // https://cn.developers.tron.network/reference#sendtrx
      tradeobj = await tronWeb.transactionBuilder.sendTrx(
        opts.to,
        new BigNumber(value).multipliedBy(1e6).toNumber(),
        sendingAddr,
      )
    } else {
      const parameter1 = [
        { type: 'address', value: opts.to },
        {
          type: 'uint256',
          value: new BigNumber(value).multipliedBy(1e6).toNumber(),
        },
      ]
      const response = await tronWeb.transactionBuilder.triggerSmartContract(
        CONTRACTS[coinType],
        'transfer(address,uint256)',
        {},
        parameter1,
        tronWeb.address.toHex(sendingAddr),
      )
      if (!response.result.result) {
        callback('sign tron trc20 result error', JSON.stringify(response))
        return
      }
      tradeobj = response.transaction
    }

    const {
      result: signedtxn,
      error,
      errorMsg,
    } = await Fetch.post(`${API_URL}/ws/relay`, {
      type: 'TRX_SIGN_TX',
      tradeobj,
      path: sendingAddrPath,
      to: opts.to,
      value,
    })
    console.log('signedtxn', signedtxn)

    if (error || errorMsg) {
      callback(error || errorMsg)
      return
    }

    const res = await this.broadcastTx(signedtxn)
    callback(null, { txid: res.txid, nonce: 0 }, id, coinType, collectPath)
  }

  // 接口有时会返回正确结果但不上链，重复几次确保成功率
  async broadcastTx(signedtxn) {
    let receipt
    let times = 3
    do {
      receipt = await tronWeb.trx.sendRawTransaction(signedtxn)
    } while(times-- > 1)
    console.log('receipt', receipt)
    return receipt
  }

  // https://cn.developers.tron.network/reference#freezebalance
  async freezeBalance(address, type = TRON_FREEZE.ENERGY) {
    const walletCollectingAddr = localStorage.getItem(TRX_MAIN_WALLET_KEY)

    console.log('freezeBalance address', address)
    console.log('freezeBalance walletCollectingAddr', walletCollectingAddr)

    let amount = prompt('数量(Trx)?')
    if (!amount) return
    amount = parseFloat(amount)

    const days = 3
    console.log('type', type)
    const tradeobj = await tronWeb.transactionBuilder.freezeBalance(
      tronWeb.toSun(amount),
      days,
      type,
      walletCollectingAddr,
      address,
    )

    const { result: signedtxn } = await Fetch.post(`${API_URL}/ws/relay`, {
      type: 'TRX_SIGN_TX',
      tradeobj,
      path: TRX_MAIN_WALLET_FULL_PATH,
    })
    console.log('signedtxn', signedtxn)

    const res = await this.broadcastTx(signedtxn)
    return res
  }

  // https://cn.developers.tron.network/reference#freezebalance
  async unFreezeBalance(address, type = TRON_FREEZE.ENERGY) {
    const walletCollectingAddr = localStorage.getItem(TRX_MAIN_WALLET_KEY)

    console.log('unfreezeBalance address', address)
    console.log('unfreezeBalance walletCollectingAddr', walletCollectingAddr)

    // let amount = prompt('数量('+ type +')?')
    // if (!amount) return
    // amount = parseFloat(amount)
    console.log('type', type)
    const tradeobj = await tronWeb.transactionBuilder.unfreezeBalance(type, walletCollectingAddr, address)

    const { result: signedtxn } = await Fetch.post(`${API_URL}/ws/relay`, {
      type: 'TRX_SIGN_TX',
      tradeobj,
      path: TRX_MAIN_WALLET_FULL_PATH,
    })
    console.log('signedtxn', signedtxn)

    return await this.broadcastTx(signedtxn)
  }

  async getBalance(address) {
    return new BigNumber(await tronWeb.trx.getBalance(address)).div(1e6).toString()
  }

  async getHeight() {
    const resp = await tronWeb.trx.getNodeInfo()
    return resp.beginSyncNum
  }
}

export default new Tron()
