import { Client, HTTPTransport, RequestManager } from '@open-rpc/client-js'

class FilecoinRpc {
  client = null

  constructor() {
    const transport = new HTTPTransport('https://api.node.glif.io/rpc/v0')
    const requestManager = new RequestManager([transport])
    this.client = new Client(requestManager)
  }

  // https://lotus.filecoin.io/reference/api/chain/#chainhead
  async getChainHead() {
    const { result } = await this.client.request({ method: 'Filecoin.ChainHead' })
    return result
  }

  async getHeight() {
    const result = await this.getChainHead()
    return result.Height
  }
}

export const filecoinRpc = new FilecoinRpc()
