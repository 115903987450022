import React from 'react'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Robot from '../components/Robot'
import Wallet from '../components/Wallet'
import { LoginPage } from './Login'
import Main from './Main'
import Layout from '../components/MyLayout'
import Stat from '../components/Stat'

function RouterView() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route
            path="/home"
            element={
              <Private>
                <Main />
              </Private>
            }
          />
          <Route
            path="/wallet"
            element={
              <Private>
                <Wallet />
              </Private>
            }
          />
          <Route
            path="/robot"
            element={
              <Private>
                <Robot />
              </Private>
            }
          />
          <Route
            path="/stat"
            element={
              <Private>
                <Stat />
              </Private>
            }
          />
          <Route exact path="/login" element={<LoginPage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}

function isAuthenticated() {
  return localStorage.getItem('api.token') != null
}

function Private({ children }) {
  const location = useLocation()
  return isAuthenticated() ? children : <Navigate replace to={'/login'} state={{ from: location }} />
}

export default RouterView
