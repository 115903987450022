import React, { Component } from 'react'
import { setWallet } from '../services/api'
import { getAddress, getDepositXPubKey } from '../services/address'
import { DOGE_MAIN_WALLET_KEY, DOGE_MAIN_WALLET_FULL_PATH, DOGE_XPUB_KEY, DOGE_USER_WALLET_BASE_PATH } from '../config'

export default class DogeWallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      walletId: null,
      changeAddr: localStorage.getItem(DOGE_MAIN_WALLET_KEY),
      xpub: localStorage.getItem(DOGE_XPUB_KEY),
      show: false,
    }
  }

  onClickGetXpub = () => {
    getDepositXPubKey(DOGE_USER_WALLET_BASE_PATH, (e, r) => {
      if (r) {
        this.setState({ xpub: r })
        localStorage.setItem(DOGE_XPUB_KEY, r)
      }
    })
  }

  onClickClearStorage = () => {
    localStorage.removeItem(DOGE_XPUB_KEY)
    window.location.reload()
  }

  onClickSaveWallet = async () => {
    try {
      const r = await setWallet({
        coin: 'DOGE',
        coinType: 'DOGE',
        xpub: localStorage.getItem(DOGE_XPUB_KEY),
        xpubPath: DOGE_USER_WALLET_BASE_PATH,
        testnet: true,
        sendingAddr: '',
      })
      this.setState({ walletId: r.id })
      alert('保存成功')
    } catch (e) {
      alert(`报错失败: ${e.message}`)
    }
  }

  _toggle = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  async onClickGetSendingAddress() {
    const changeAddr = await getAddress(DOGE_MAIN_WALLET_FULL_PATH, 'DOGE')
    console.log('changeAddr', changeAddr)
    this.setState({ changeAddr })
    localStorage.setItem(DOGE_MAIN_WALLET_KEY, changeAddr)
  }

  render() {
    if (!this.state.show) {
      return <h2 onClick={this._toggle}>DOGE Wallet (click to show)</h2>
    }
    return (
      <div>
        <h2 onClick={this._toggle}>DOGE Wallet (click to hide)</h2>
        <div>
          <p>wallet id: {this.state.walletId || 'wallet not saved'}</p>
          <button onClick={this.onClickClearStorage} className={'btn btn-sm'}>
            clear storage cache
          </button>
          <button onClick={this.onClickSaveWallet} className={'btn btn-sm'}>
            save wallet
          </button>
        </div>
        <div>
          <p>wallet xpub: {this.state.xpub}</p>
          <button onClick={this.onClickGetXpub} className={'btn btn-sm'}>
            get xpub key
          </button>
        </div>
        <div>
          <p>wallet change addr: {this.state.changeAddr}</p>
          <button onClick={() => this.onClickGetSendingAddress()} className={'btn btn-sm'}>
            get change addr
          </button>
        </div>
      </div>
    )
  }
}
