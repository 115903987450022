import TrezorConnect from '@trezor/connect-web'
import BigNumber from 'bignumber.js'
import _ from 'lodash'
import ltc from './ltc'
import * as api from './api'
import * as utils from '../utils'

/**
 * @param coin string
 * @param _inputs Array<{address: string, path: string, txid: string, vout: number, amount: string}>
 * @param collector Object{address: string, path: string}
 * @param fee number
 * @param batch number
 */
export async function collectBtcFamily(coin, _inputs, collector, fee, batch) {
  coin = coin.toUpperCase()
  fee = polyfillFee(coin, fee)

  const yes = window.confirm(
    `即将进行${coin} tx，请检查参数是否正确。收集地址为${collector.address}，path为${collector.path}。uxto输入总计${_inputs.length}个。费率为${fee}。以${batch}为批次执行。`,
  )
  if (!yes) throw new Error('用户取消')

  const batchInputs = _.chunk(_inputs, batch)
  for (let i = 0; i < batchInputs.length; i++) {
    console.log(`正在执行第${i + 1}批次`)
    const inputs = batchInputs[i]
    const txid = await signBtcFamilyTx(coin, inputs, [], collector, fee)
    alert(`第${i + 1}批次执行完毕，txid为${txid}`)
  }
}

/**
 * @param coin string
 * @param inputs Array<{address: string, path: string, txid: string, vout: number, amount: string}>
 * @param outputs Array<{to: string, value: number}>
 * @param collector Object{address: string, path: string}
 * @param fee number
 */
export async function withdrawBtcFamily(coin, inputs, outputs, collector, fee) {
  coin = coin.toUpperCase()
  fee = polyfillFee(coin, fee)

  const yes = window.confirm(
    `即将进行${coin} tx，请检查参数是否正确。收集地址为${collector.address}，path为${collector.path}。uxto输入总计${inputs.length}个，输出总计${outputs.length}个。费率为${fee}。`,
  )
  if (!yes) throw new Error('用户取消')

  return await signBtcFamilyTx(coin, inputs, outputs, collector, fee)
}

/**
 * @param coin string
 * @param inputs Array<{address: string, path: string, txid: string, vout: number, amount: string}>
 * @param outputs Array<{to: string, value: number}>
 * @param collector Object{address: string, path: string}
 * @param fee number
 */
export async function signBtcFamilyTx(coin, inputs, outputs, collector, fee) {
  coin = coin.toUpperCase()

  // 总UXTO累计金额
  console.log(`输入数量：${inputs.length}`)
  const total = inputs.reduce((acc, it) => acc.plus(it.amount), new BigNumber(0))
  console.log(`输入总计：${total.toNumber()} ${coin}`)
  if (total.isZero()) throw new Error('账户余额为空')

  // 格式化input
  if (inputs.length === 0) throw new Error('没有input uxto')
  inputs = inputs.map((it) => ({
    amount: new BigNumber(it.amount).multipliedBy(1e8).toString(),
    address_n: utils.path2AddressN(it.path),
    prev_index: it.vout,
    prev_hash: it.txid,
  }))

  // 总转出金额
  console.log(`输出数量：${outputs.length}`)
  let consumed = outputs.reduce((acc, it) => acc.plus(it.value), new BigNumber(0))
  console.log(`输出总计：${consumed.toNumber()} ${coin}`)

  // 格式化输出
  outputs = outputs.map((it) => {
    let address = it.to
    if (coin === 'LTC') {
      address = address.startsWith('3') ? ltc.rotateAddressM2L(address) : address
    }
    return {
      address,
      amount: new BigNumber(it.value).multipliedBy(1e8).toString(),
      script_type: 'PAYTOADDRESS',
    }
  })

  // tx大小
  const txSizeBytes = utils.estimateTxSizeByByte(inputs.length, outputs.length + 1)
  console.log(`tx大小：${txSizeBytes} bytes`)

  // gas fee
  fee = new BigNumber(txSizeBytes).dividedBy(1e3).multipliedBy(fee).decimalPlaces(8)
  if (fee.isNaN()) throw new Error('gas费用计算失败')
  console.log(`gas费：${fee.toNumber()} ${coin}`)

  // 返还金额
  const payBack = new BigNumber(total).minus(consumed).multipliedBy(1e8).minus(fee.multipliedBy(1e8))
  console.log(`找零：${payBack.div(1e8).toNumber()} ${coin} ${collector.address} ${collector.path}`)

  // 返还output
  outputs.push({
    address: collector.address,
    amount: payBack.toString(),
    script_type: 'PAYTOADDRESS',
  })

  // 签名tx
  const unsignedTx = { coin, inputs, outputs }
  console.log(`未签名tx数据：${JSON.stringify(unsignedTx)}`)

  console.log(`正在签名...`)
  const response = await TrezorConnect.signTransaction(unsignedTx)
  if (!response.success) {
    throw new Error(`签名失败：${JSON.stringify(response.payload)}`)
  }
  console.log(`签名成功：${JSON.stringify(response.payload)}`)
  // payload -> signatures: string[]; serializedTx: string; txid?: string;
  const signedTx = response.payload.serializedTx

  // 发送tx
  console.log(`正在发送tx...`)
  let txid
  if (coin === 'LTC') {
    txid = await api.sendLtcTx(signedTx)
  } else if (coin === 'DOGE') {
    const res = await TrezorConnect.pushTransaction({ tx: signedTx, coin: 'DOGE' })
    if (!res.success) throw res.payload
    txid = res.payload.txid
  }
  console.log(`tx发送成功, txid：${txid}`)

  return txid
}

function polyfillFee(coin, fee) {
  if (coin === 'DOGE') {
    fee = '1'
  }
  fee = prompt('设置gas价格（/1000bytes）', `${fee}`)
  if (!fee) throw new Error('未设置gas价格')
  fee = parseFloat(fee)
  return fee
}
