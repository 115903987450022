import React, { Component } from 'react'
import { format } from '../utils'
import { fetchBTCBalance, fetchETHBalance } from '../services/api'
import { getChainByCoinType } from '../services/token_manager'
import Tron from '../services/tron'
import * as filecoinUtils from '../utils/wallet/hd/ledger/filecoin'
import { zcashRpc } from '../services/zcash'
import { etcService } from '../services/eth_family'

const baseCoins = ['ETH', 'ETC', 'BTC', 'TRX', 'FIL', 'ZEC', 'LTC', 'DOGE']

async function getBalance(address, baseCoin) {
  let balance = -1
  switch (baseCoin) {
    case 'BTC':
      balance = await fetchBTCBalance(address)
      break
    case 'ETH':
      balance = await fetchETHBalance(address, getChainByCoinType(baseCoin))
      break
    case 'ETC':
      balance = await etcService.getBalance(address)
      break
    case 'TRX':
      balance = await Tron.getBalance(address)
      break
    case 'FIL':
      balance = await filecoinUtils.getBalance(address)
      break
    case 'ZEC':
      balance = (await zcashRpc.getBalance(address)) / 1e8
      break
    default:
      throw new Error('getBalance invalid params ' + address + baseCoin)
  }
  return balance
}

export default class BalanceRow extends Component {
  state = {
    baseBalance: null,
  }

  handleBaseBalance = async (address, coin) => {
    const balance = await getBalance(address, coin)
    console.log('handle base balance %s  %s', coin, balance)
    this.setState({ baseBalance: balance })
  }

  render() {
    const { address, balance, applyCollect, applyCommission, coin, baseCoin } = this.props.item

    const rows = [
      {
        coinType: coin,
        balance,
      },
      {
        coinType: baseCoin,
        balance: this.state.baseBalance,
      },
    ]

    return rows.map((item, index) => {
      const isBaseCoin = baseCoins.indexOf(item.coinType) > -1
      // 这几种不单独收集
      const disableCollect =
        item.coinType === 'BTC' || item.coinType === 'TRX' || item.coinType === 'LTC' || item.coinType === 'DOGE'
      return (
        <tr key={index}>
          <td>{index === 0 ? address : null}</td>
          <td>{format(item.balance)}</td>
          <td>{item.coinType}</td>
          <td>
            <button
              disabled={!isBaseCoin}
              onClick={() => this.handleBaseBalance(address, item.coinType)}
              className={'btn btn-sm'}
            >
              click
            </button>
          </td>
          <td>
            <button
              disabled={isBaseCoin}
              onClick={() => applyCommission(address, item.coinType)}
              className={'btn btn-sm'}
            >
              click
            </button>
          </td>
          <td>
            <button
              disabled={disableCollect}
              onClick={() => applyCollect(address, item.coinType, item.balance)}
              className={'btn btn-sm'}
            >
              click
            </button>
          </td>
        </tr>
      )
    })
  }
}
