import React, { Component } from 'react'
import { rescan } from '../services/api'
import assert from 'assert'

export default class BtcPatch extends Component {
  state = {
    address: '',
    transactions: [''],
    show: false,
    coin: 'BTC',
  }
  updateTxns = (value, index) => {
    const { transactions: newTxns } = this.state
    newTxns.splice(index, 1, value)
    this.setState({
      transactions: [...newTxns],
    })
  }
  updateAddress = (address) => {
    this.setState({ address })
  }
  updateCoin = (coin) => {
    this.setState({ coin })
  }
  send = () => {
    const { address, transactions } = this.state
    assert.notEqual(address, false)
    rescan(
      address,
      transactions.filter((item) => item),
      this.state.coin,
    )
      .then((res) => {
        console.log('rescan res is ', res)
        alert('rescan success')
      })
      .catch((e) => {
        console.error('rescan error is ', e)
        alert(e)
      })
  }
  addRow = (index) => {
    const { transactions: newTxns } = this.state
    newTxns.splice(index + 1, 0, '')
    this.setState({
      transactions: [...newTxns],
    })
  }
  delRow = (index) => {
    const { transactions: newTxns } = this.state
    if (newTxns.length === 1) return
    newTxns.splice(index, 1)
    this.setState({
      transactions: [...newTxns],
    })
  }
  _toggle = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  render() {
    const { transactions, address, coin } = this.state
    if (!this.state.show) {
      return <h2 onClick={this._toggle}>Patch (click to show)</h2>
    }
    return (
      <div>
        <h2 onClick={this._toggle}>Patch(click to hide)</h2>
        <div style={styles.container}>
          <div style={styles.wrapper}>
            <div style={styles.propWidth}>type:</div>
            <b>rescan</b>
          </div>
          <div style={styles.wrapper}>
            <div style={styles.propWidth}>coin:</div>
            <input
              type="coin"
              style={{ flex: 1 }}
              value={coin}
              onChange={(e) => this.updateCoin(e.target.value)}
              className="input input-bordered input-sm"
            />
          </div>
          <div style={styles.wrapper}>
            <div style={styles.propWidth}>address:</div>
            <input
              type="text"
              className="input input-bordered input-sm"
              style={{ flex: 1 }}
              value={address}
              onChange={(e) => this.updateAddress(e.target.value)}
            />
          </div>
          <div style={styles.wrapper}>
            <div style={styles.propWidth}>transactions:</div>
            <div style={styles.column}>
              {transactions.map((item, index) => TxRow(item, index, this))}
              <button style={styles.btn} onClick={this.send} className={'btn btn-sm'}>
                send
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const TxRow = (item, index, _this) => {
  return (
    <div key={index} style={styles.itemWrapper}>
      <input
        type="text"
        style={{ flex: 1 }}
        value={item}
        onChange={(e) => _this.updateTxns(e.target.value, index)}
        className="input input-bordered input-sm"
      />
      <button onClick={() => _this.addRow(index)} className={'btn btn-sm'}>
        add row
      </button>
      <button onClick={() => _this.delRow(index)} className={'btn btn-sm'}>
        del row
      </button>
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  propWidth: {
    width: '20%',
    textAlign: 'right',
    marginRight: '1rem',
  },
  itemWrapper: {
    display: 'flex',
    flex: 1,
  },
  btn: {
    width: '3rem',
  },
}
