import React, { Component } from 'react'

import Assets from './Assets'

import EthWallet from './EthWallet'
import TronWallet from './TronWallet'
import UsdtWallet from './UsdtWallet'
import LtcWallet from './LtcWallet'
import DogeWallet from './DogeWallet'

import BtcPatch from './BtcPatch'
import { FilecoinConfig } from './chain/filecoin/FilecoinConfig'
import { ZCashConfig } from './chain/zcash/ZCashConfig'

class Wallet extends Component {
  render() {
    return (
      <div className={'space-y-4'}>
        <BtcPatch />
        <Assets />
        <EthWallet />
        <TronWallet />
        <UsdtWallet />
        <LtcWallet />
        <DogeWallet />
        <FilecoinConfig />
        <ZCashConfig />
      </div>
    )
  }
}

export default Wallet
