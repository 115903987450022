import React, { Component } from 'react'
import { fetchETHBalance } from '../services/api'
import { ETH_MAIN_WALLET_KEY } from '../config'

export default class Assets extends Component {
  state = {
    balanceObj: {},
    show: false,
  }
  refreshList = async () => {
    const addr = localStorage.getItem(ETH_MAIN_WALLET_KEY)
    if (addr) {
      const eth = await fetchETHBalance(addr)
      this.setState({
        balanceObj: {
          ETH: eth,
        },
      })
    }
  }

  componentDidMount() {
    this.refreshList()
    this.intervalId = setInterval(() => this.refreshList(), 10000)
  }

  _toggle = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  render() {
    const { balanceObj, show } = this.state
    if (!show) {
      return <h2 onClick={this._toggle}>Assets (click to show)</h2>
    }
    return (
      <div style={styles.container}>
        <h2 onClick={this._toggle}>Assets (click to hide)</h2>
        {Object.keys(balanceObj).map((item, index) => {
          return (
            <div key={index}>
              <p>{`${item}: ${balanceObj[item]}`}</p>
            </div>
          )
        })}
      </div>
    )
  }
}

const styles = {
  container: {
    maxHeight: '30rem',
    overflow: 'auto',
    overflowX: 'visible',
    paddingRight: '1rem',
  },
}
