import React, { Component } from 'react'
import {
  addRobotTxConfig,
  deleteRobotTxConfig,
  fetchRobotAddress,
  fetchRobotBalance,
  fetchRobotTxConfig,
  fetchUsdtBalance,
  fetchUtxo,
  fetchUtxoTotal,
  getETHProvider,
  putNewAddress,
  queryRobotTodaySignedWithdrawTx,
  setWithdrawNonce,
  updateRobotTxConfig,
  withdrawFromRobot,
} from '../services/api'
import { getChainByCoinType, getTokens } from '../services/token_manager'
import BigNumber from 'bignumber.js'
import { getContract, signAndSendTx } from '../services/trezor'
import { estimateTxSizeByByte, format } from '../utils'
import { USDT_MAIN_WALLET_KEY, ETH_MAIN_WALLET_KEY } from '../config'
import QRCode from 'qrcode'

class Robot extends Component {
  state = {
    address: '', // old eth address
    btcAddress: '',
    btcQrcode: '',
    usdtAddress: '',
    nasAddress: '',
    configs: {},
    coin: '',
    amountPer: '',
    amountPerDay: '',
    interval: '',
    balances: {},
    totalSignedTxs: {},
  }

  componentDidMount() {
    this._query()
    setTimeout(this._getBalance, 2000)
  }

  _updateAmountPer = (id) => {
    const value = prompt('每笔限额?')
    if (!value) return
    const { configs } = this.state
    configs[id].amountPer = parseFloat(value)
    this.setState({
      configs: { ...configs },
    })
  }
  _updateAmountPerDay = (id) => {
    const value = prompt('每日限额?')
    if (!value) return
    const { configs } = this.state
    configs[id].amountPerDay = parseFloat(value)
    this.setState({
      configs: { ...configs },
    })
  }
  _updateIntreval = (id) => {
    const value = prompt('间隔(分钟)')
    if (!value) return
    const { configs } = this.state
    configs[id].interval = parseFloat(value)
    this.setState({
      configs: { ...configs },
    })
  }
  _updateAlertAmount = (id) => {
    const value = prompt('余额警告数量')
    if (!value) return
    const { configs } = this.state
    configs[id].amountAlert = parseFloat(value)
    this.setState({
      configs: { ...configs },
    })
  }
  _query = async () => {
    const { result: address } = await fetchRobotAddress()
    const { result: btcAddress } = await fetchRobotAddress('BTC')
    const btcQrcode = await QRCode.toDataURL(btcAddress)
    const { result: usdtAddress } = await fetchRobotAddress('USDT')
    const configArr = await fetchRobotTxConfig()
    const configs = configArr.reduce((acc, item) => ({ ...acc, [item.id]: item }), {})
    this.setState({
      address,
      btcQrcode,
      btcAddress,
      usdtAddress,
      configs,
    })
  }
  _delete = async (id) => {
    const confirm = window.confirm('确认删除?')
    if (!confirm) return

    await deleteRobotTxConfig(id)
    await this._query()
  }
  _apply = async (id) => {
    const config = this.state.configs[id]
    console.log('apply ', config)
    const res = await updateRobotTxConfig(config)
    console.log('apply config ', res)
    alert('修改成功')
  }
  _add = async () => {
    const { coin, amountPer, amountPerDay, interval } = this.state
    const config = {
      coin,
      amountPer,
      amountPerDay,
      interval,
    }
    const res = await addRobotTxConfig(config)
    console.log('_add config ', res)
    this.setState({
      coin: '',
      amountPer: '',
      amountPerDay: '',
      interval: '',
    })
    await this._query()
  }
  _deposit = (coinType) => {
    console.log('deposit ', coinType)
    const value = prompt('存币数量?')
    if (value === null) return
    console.log('存币数量: ', value)
    let robotAddr
    let { address: ethAddress, btcAddress, usdtAddress } = this.state
    switch (coinType) {
      case 'BTC':
        robotAddr = btcAddress
        break
      case 'USDT':
        robotAddr = usdtAddress
        break
      case 'BTC(USDT)':
        robotAddr = usdtAddress
        break
      case 'ETH':
      default:
        if (coinType === 'ETH' || getTokens()[coinType]) {
          robotAddr = ethAddress
        } else {
          console.error('invalid coin ', coinType)
          return
        }
        break
    }

    signAndSendTx(
      {
        to: robotAddr,
        value: value,
        coinType: coinType === 'BTC(USDT)' ? 'BTC' : coinType,
      },
      async (err, result) => {
        if (err) {
          console.log('_deposit err ', err)
          return
        }
        console.log('deposit finish ', err, result)
        const { nonce } = result

        if (coinType === 'ETH' || getTokens()[coinType]) {
          const key = `trezor.${getChainByCoinType(coinType)}`
          await setWithdrawNonce(nonce, key)
          console.log('updateDBnonce %s', key, nonce)
        }

        console.info('----------- success ----------------')
        alert('成功')
      },
    )
  }

  _withdraw = async (coinType) => {
    let receiver = ''
    switch (coinType) {
      case 'BTC(USDT)':
      case 'USDT':
        receiver = localStorage.getItem(USDT_MAIN_WALLET_KEY)
        break
      case 'ETH':
      default:
        if (coinType === 'ETH' || getTokens()[coinType]) {
          receiver = localStorage.getItem(ETH_MAIN_WALLET_KEY)
        } else {
          console.error('invalid coin ', coinType)
          return
        }
        break
    }
    if (!receiver) {
      alert('收款地址不能为空')
      return
    }
    const confirm1 = window.confirm('收款地址' + receiver)
    if (!confirm1) return

    const { balances } = this.state
    let amount
    let sendingAddr = this.state.address

    switch (coinType) {
      case 'ETH': // unit eth
        const price = await getETHProvider({ coinType }).eth.getGasPrice()
        console.log('withdraw %s price', coinType, price)
        const fee = new BigNumber(price).plus(1e9).multipliedBy(21000).div(1e18)
        amount = new BigNumber(balances[coinType]).minus(fee).toString()
        break
      case 'BTC(USDT)': // unit btc
        sendingAddr = this.state.usdtAddress
        const utxo = await fetchUtxo(sendingAddr)
        const priceInBtc = 0.00004
        const bytes = estimateTxSizeByByte(utxo.length, 1)
        const feeBN = new BigNumber(bytes).dividedBy(1e3).multipliedBy(priceInBtc).decimalPlaces(8)
        const sumAmount = this.state.balances['BTC(USDT)']
        const amountBN = new BigNumber(sumAmount).minus(feeBN)
        amount = amountBN.toString()
        break
      case 'USDT':
        sendingAddr = this.state.usdtAddress
        amount = new BigNumber(balances[coinType]).toString()
        break
      default:
        amount = balances[coinType]
    }

    const withdrawTx = {
      address: receiver,
      sendingAddr,
      coinType: coinType === 'BTC(USDT)' ? 'BTC' : coinType,
      amount,
    }
    console.log('withdrawTx', withdrawTx)
    const resp = await withdrawFromRobot(withdrawTx)
    console.log('finish withdrawFromRobot', resp)
  }

  updateValue = (value, type) => {
    this.setState({
      [type]: value,
    })
  }

  _getBalance = async () => {
    const { address, configs, usdtAddress } = this.state
    if (address) {
      const tokens = getTokens()
      const ethBalance = await getETHProvider().eth.getBalance(address)
      const btcUsdtBalance = await fetchUtxoTotal(usdtAddress)
      const balances = {
        ETH: new BigNumber(ethBalance).div(1e18).toString(),
        'BTC(USDT)': new BigNumber(btcUsdtBalance).div(1e8).toString(),
      }
      const totalSignedTxs = {}

      for (const id of Object.keys(configs)) {
        const coinType = configs[id].coin
        let balance = 0

        try {
          switch (coinType) {
            case 'USDT':
              const balanceInBtcString = await fetchUsdtBalance(usdtAddress)
              balances[coinType] = new BigNumber(balanceInBtcString || 0).div(1e8).toString()
              break
            case 'BTC':
              const res = await fetchRobotBalance()
              balances[coinType] = new BigNumber(res.payload).div(1e8).toString()
              break
            case 'ETH':
              break
            default:
              if (!tokens[coinType]) continue
              balance = await getContract(coinType).methods.balanceOf(address).call()
              balances[coinType] = new BigNumber(balance).div(10 ** tokens[coinType].decimals).toString()
          }
          totalSignedTxs[coinType] = await queryRobotTodaySignedWithdrawTx(coinType)
        } catch (error) {
          console.log('robot error ', error)
          totalSignedTxs[coinType] = -1
          balances[coinType] = -1
        }
      }
      this.setState({
        balances,
        totalSignedTxs,
      })
    }

    this.timer = setTimeout(this._getBalance, 60 * 1000)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  _renderConfigs = (configs) => {
    return (
      <table className={'table table-zebra table-compact w-full'}>
        <thead>
          <tr>
            <th>coin</th>
            <th>amountPer</th>
            <th>amountPerDay</th>
            <th>interval(min)</th>
            <th>amountAlert</th>
            <th />
            <th>balance</th>
            <th>totalSigned</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {Object.values(configs).map(({ id, coin, amountPer, amountPerDay, interval, amountAlert }, index) => (
            <tr key={id.toString()}>
              <td>{coin}</td>
              <td onClick={() => this._updateAmountPer(id)}> {format(amountPer)} </td>
              <td onClick={() => this._updateAmountPerDay(id)}>{format(amountPerDay)}</td>
              <td onClick={() => this._updateIntreval(id)}>{interval}</td>
              <td onClick={() => this._updateAlertAmount(id)}>{amountAlert}</td>
              <td>
                <button onClick={() => this._apply(id)} className={'btn btn-sm'}>
                  {' '}
                  apply
                </button>
                <button onClick={() => this._delete(id)} className={'btn btn-sm'}>
                  {' '}
                  delete
                </button>
                <button onClick={() => this._deposit(configs[id].coin)} className={'btn btn-sm'}>
                  {' '}
                  deposit
                </button>
                <button onClick={() => this._withdraw(configs[id].coin)} className={'btn btn-sm'}>
                  {' '}
                  withdraw
                </button>
              </td>
              <td>{format(this.state.balances[coin])}</td>
              <td>{format(this.state.totalSignedTxs[coin])}</td>
            </tr>
          ))}
          <tr>
            <td>
              <input
                type="text"
                value={this.state.coin}
                onChange={(e) => this.updateValue(e.target.value, 'coin')}
                className="input input-bordered input-sm"
              />
            </td>
            <td>
              <input
                type="text"
                className="input input-bordered input-sm"
                value={this.state.amountPer}
                onChange={(e) => this.updateValue(e.target.value, 'amountPer')}
              />
            </td>
            <td>
              <input
                type="text"
                className="input input-bordered input-sm"
                value={this.state.amountPerDay}
                onChange={(e) => this.updateValue(e.target.value, 'amountPerDay')}
              />
            </td>
            <td>
              <input
                type="text"
                className="input input-bordered input-sm"
                value={this.state.interval}
                onChange={(e) => this.updateValue(e.target.value, 'interval')}
              />
            </td>
            <td>
              <button onClick={() => this._add()} className={'btn btn-sm'}>
                {' '}
                add
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  _generateNewAddress = async () => {
    const res = await putNewAddress()
    console.log('res', res)
    if (res.errMsg) {
      alert(res.errMsg)
    } else {
      this._query()
    }
  }

  render() {
    const { address, configs, balances, btcAddress, usdtAddress, btcQrcode } = this.state
    return (
      <div>
        <div style={styles.alignStart}>{`ETH Address: ${address}`}</div>
        <div style={styles.alignStart}>
          {`BTC Address: ${btcAddress}`}
          <button onClick={() => this._generateNewAddress()} className={'btn btn-sm'}>
            {' '}
            Generate New Address
          </button>
        </div>
        <div style={styles.alignStart}>{btcQrcode && <img src={btcQrcode} alt={''} />}</div>
        <div style={styles.alignStart}>{`USDT Address: ${usdtAddress}`}</div>
        <div style={styles.alignStart}>
          ETH:
          {format(balances.ETH)}
          <button onClick={() => this._deposit('ETH')} className={'btn btn-sm'}>
            {' '}
            deposit
          </button>
          <button onClick={() => this._withdraw('ETH')} className={'btn btn-sm'}>
            {' '}
            withdraw
          </button>
        </div>
        <div style={styles.alignStart}>
          BTC(USDT):
          {format(balances['BTC(USDT)'])}
          <button onClick={() => this._deposit('BTC(USDT)')} className={'btn btn-sm'}>
            {' '}
            deposit
          </button>
          <button onClick={() => this._withdraw('BTC(USDT)')} className={'btn btn-sm'}>
            {' '}
            withdraw
          </button>
        </div>
        {this._renderConfigs(configs)}
      </div>
    )
  }
}

const styles = {
  alignStart: {
    textAlign: 'start',
  },
}

export default Robot
