import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import _fetch from '@amanooo/fetch'
import { satoshi2btc } from '../utils'
import tron from './tron'
import { getChainByCoinType } from './token_manager'
import { API_URL, APP_ENV, ETC_MAINNET_RPC_URL, ETH_NETWORK, INSIGHT_URL, SPRING_URL } from '../config'
import axios from 'axios'
import { dogecoinRpc } from './dogecoin'

_fetch.headers = getHeaders()
_fetch.credentials = 'omit'
_fetch.retryOpts = {
  retries: 2,
  delay: 2 * 1000,
  timeout: 60 * 1000,
}

export const fetcher = _fetch

// https://axios-http.com/docs/req_config
export function relay(config) {
  return axios.post(`${API_URL}/relay`, config)
}

function getHeaders(token) {
  return {
    'content-type': 'application/json',
    'x-auth-token': token || localStorage.getItem('api.token'),
  }
}

async function fetchJson(url, params) {
  if (params) {
    const query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&')
    url = `${url}?${query}`
  }
  const res = await fetch(url, { headers: getHeaders() })
  return await res.json()
}

export function setApiToken(token) {
  localStorage.setItem('api.token', token)
  fetcher.headers = getHeaders(token)
}

export async function login(username, password) {
  const res = await fetch(`${API_URL}/login`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ username, password }),
  })
  return res.json()
}

/**
 * 获取账户全部uxto
 */
export async function fetchAllUxto(coin) {
  const { uxtos, collector, fee } = await fetcher.get(`${API_URL}/tx/compose/${coin.toUpperCase()}`, undefined, undefined, { retries: 1 })
  return { uxtos, collector, fee }
}

/**
 * 获取大地址uxto
 */
export async function fetchCollectorUxto(coin) {
  const { uxtos, collector, fee } = await fetcher.get(`${API_URL}/tx/uxto/${coin.toUpperCase()}`, undefined, undefined, { retries: 1 })
  return { uxtos, collector, fee }
}

export async function fetchZECUnspendUXTOs(address) {
  return fetcher.get(`${API_URL}/ZEC/wallet/${address}/uxtos`)
}

export async function fetchBalanceList(key, minValue, maxValue) {
  const res = await fetchJson(`${API_URL}/v2/balance/list`, { key, minValue, maxValue })
  return res.payload
}

export function fetchSignedTx() {
  return fetch(`${API_URL}/wallet/txps/signed`, { method: 'POST', headers: getHeaders() }).then((r) => r.json())
}

export function fetchUnsignedTx() {
  return fetch(`${API_URL}/wallet/txps/unsigned`, { method: 'POST', headers: getHeaders() }).then((r) => r.json())
}

export function signTx({ txHash, nonce, id, fee = 0 }) {
  return fetcher.post(`${API_URL}/wallet/signtx`, { txHash, nonce, id, fee })
}

export function setWallet(payload) {
  return fetcher.post(`${SPRING_URL}/wallet/create`, { main: true, ...payload })
}

export function applyCollect(address, coinType, amount = undefined) {
  return fetcher.post(`${API_URL}/tx/collect`, { address, coinType, amount })
}

export function applyCommission(address, coinType, amount) {
  return fetcher.post(`${API_URL}/tx/commission`, { address, coinType, amount })
}

export async function sendBtcTx(rawtx) {
  return fetcher.post(`${INSIGHT_URL}tx/send`, { rawtx }, { headers: { 'content-type': 'application/json' } })
}

export async function sendLtcTx(serializedTx) {
  return fetcher.post(API_URL + '/tx/send/LTC', { serializedTx })
}

export async function modifyTxSend(id, info) {
  return fetcher.post(API_URL + '/tx/modify', { id, info })
}

export async function fetchDogeBlockHeight() {
  return dogecoinRpc.getHeight()
}

export function fetchTronBlockHeight() {
  return tron.getHeight()
}

export function fetchUtxo(addr) {
  return fetch(`${INSIGHT_URL}addr/${addr}/utxo`).then((r) => r.json())
}

export async function fetchUtxoTotal(addr) {
  const balance = await fetch(`${INSIGHT_URL}addr/${addr}/balance`).then((r) => r.json())
  const unconfirm = await fetch(`${INSIGHT_URL}addr/${addr}/unconfirmedBalance`).then((r) => r.json())
  return new BigNumber(balance).plus(new BigNumber(unconfirm)).toNumber()
}

export async function fetchBTCBalance(addr) {
  const balanceInSatoshi = await fetchUtxoTotal(addr)
  return satoshi2btc(balanceInSatoshi)
}

export const getETHProvider = ({ chain, coinType } = { chain: ETH_NETWORK }) => {
  let _chain = chain || getChainByCoinType(coinType)
  let httpProvider
  switch (_chain) {
    case 'mainnet':
    case 'ropsten':
      httpProvider = `https://${_chain}.infura.io/v3/b8ff618b80dc438f9b5c786be8585164`
      break
    case 'etc':
      httpProvider = ETC_MAINNET_RPC_URL
      break
    default:
      throw new Error(`invalid chain ${_chain}, coinType is ${coinType}`)
  }
  return new Web3(new Web3.providers.HttpProvider(httpProvider))
}

export const getETHChainId = ({ chain, coinType } = { chain: ETH_NETWORK }) => {
  const _chain = chain || getChainByCoinType(coinType)
  let chainId
  switch (_chain) {
    case 'mainnet':
      chainId = 1
      break
    case 'ropsten':
      chainId = 3
      break
    case 'etc':
      chainId = 61
      break
    default:
      throw new Error(`invalid chain ${_chain}, coinType is ${coinType}`)
  }
  return chainId
}

export async function fetchETHBalance(address, chain) {
  const params = chain ? { chain } : undefined
  const balanceInWei = await getETHProvider(params).eth.getBalance(address)
  return getETHProvider(params).utils.fromWei(balanceInWei)
}

export const fetchETHBlockHeight = (params) => getETHProvider(params).eth.getBlockNumber()

export const fetchGasPriceWei = async (coinType) => {
  const params = coinType ? { coinType } : undefined
  let priceInWei = await getETHProvider(params).eth.getGasPrice()
  const result = getETHProvider(params).utils.fromWei(priceInWei, 'gwei')
  const priceInGwei = Math.ceil(Number.parseFloat(result))
  priceInWei = getETHProvider(params).utils.toWei(priceInGwei.toString(), 'gwei')
  return priceInWei
}

export const cancelSendTx = async (id, callback) => {
  const result = await fetch(`${API_URL}/tx/cancel`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ id }),
  }).then((r) => r.json())
  callback(result)
  return result
}

export const fetchTokens = () => {
  const opts = {
    method: 'POST',
    headers: getHeaders(),
  }
  return fetch(`${API_URL}/tokens`, opts).then((r) => r.json())
}

export async function fetchXpub(coin, path) {
  let result
  switch (coin) {
    case 'TRX':
      result = await fetcher.post(`${API_URL}/ws/relay`, { path, type: 'TRX_EXPORT_XPUB' })
      break
    default:
      throw new Error('getxpub invalid coin ')
  }
  return result
}

export async function fetchExportAddress(path, type) {
  return fetcher.post(`${API_URL}/ws/relay`, { path, type })
}

export const rescan = (address, transactions, coin = 'BTC') => {
  return fetch(`${SPRING_URL}/bank/rescan`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ coin, address, transactions }),
  }).then((r) => r.text())
}

const defaultChain = APP_ENV === 'prod' ? 'mainnet' : 'ropsten'

export function fetchWithdrawNonce(chain = defaultChain) {
  return fetch(`${API_URL}/withdraw/${chain}/nonce`, {
    headers: getHeaders(),
  }).then((r) => r.json())
}

export function setWithdrawNonce(nonce, chain = defaultChain) {
  return fetch(`${API_URL}/withdraw/${chain}/nonce`, {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify({ nonce }),
  }).then((r) => r.json())
}

export async function fetchUsdtBalance(addr) {
  const url = `${API_URL}/omni/v2/address/addr/${addr}`
  const res = await fetchJson(url)
  if (res.error || !res[addr]) {
    throw new Error('fetch usdt balance error' + res.error)
  }
  const usdt = res[addr].balance.find((item) => item.id === '31')
  return usdt && usdt.value
}

export function fetchRobotAddress(coin = 'ETH') {
  return fetch(`${API_URL}/robot/address/${coin}`, {
    headers: getHeaders(),
  }).then((r) => r.json())
}

export function fetchRobotTxConfig() {
  return fetch(`${API_URL}/robot/txconfig`, {
    headers: getHeaders(),
  }).then((r) => r.json())
}

export function updateRobotTxConfig(config) {
  return fetch(`${API_URL}/robot/updateConfig`, {
    headers: getHeaders(),
    body: JSON.stringify({ config }),
    method: 'POST',
  }).then((r) => r.json())
}

export function addRobotTxConfig(config) {
  return fetch(`${API_URL}/robot/addConfig`, {
    headers: getHeaders(),
    body: JSON.stringify({ config }),
    method: 'POST',
  }).then((r) => r.json())
}

export function deleteRobotTxConfig(id) {
  return fetch(`${API_URL}/robot/deleteConfig`, {
    headers: getHeaders(),
    body: JSON.stringify({ id }),
    method: 'POST',
  }).then((r) => r.json())
}

export function queryRobotTodaySignedWithdrawTx(coin) {
  return fetch(`${API_URL}/robot/todaySignedWithdrawTx/${coin}`, {
    headers: getHeaders(),
  }).then((r) => r.json())
}

export function withdrawFromRobot(tx) {
  return fetch(`${API_URL}/robot/withdraw`, {
    headers: getHeaders(),
    body: JSON.stringify(tx),
    method: 'POST',
  }).then((r) => r.json())
}

export function fetchStat() {
  return fetch(`${API_URL}/stat`, {
    headers: getHeaders(),
  }).then((r) => r.json())
}

export function repackage(id, priceInGwei) {
  return fetch(`${API_URL}/tx/repackage`, {
    headers: getHeaders(),
    body: JSON.stringify({ id, priceInGwei }),
    method: 'POST',
  }).then((r) => r.json())
}

export function putNewAddress() {
  return fetch(`${API_URL}/robot/address/BTC`, {
    headers: getHeaders(),
    method: 'PUT',
  }).then((r) => r.json())
}

export function fetchRobotBalance() {
  return fetch(`${API_URL}/robot/BTC/balance`, {
    headers: getHeaders(),
  }).then((r) => r.json())
}
