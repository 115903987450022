import React, { Component } from 'react'
import {
  fetchDogeBlockHeight,
  fetchETHBlockHeight,
  fetchGasPriceWei,
  fetchSignedTx,
  fetchTronBlockHeight,
  repackage,
} from '../services/api'
import BigNumber from 'bignumber.js'
import { format } from '../utils'
import { filecoinRpc } from '../services/filecoin'
import { zcashRpc } from '../services/zcash'
import { etcService } from '../services/eth_family'

const SEND_TYPE_COMMISSION = 'COMMISSION'
const SEND_TYPE_COLLECT = 'COLLECT_DEPOSIT'
const SEND_TYPE_WITHRAW = 'WITHDRAW'

async function tasker() {
  const [ethBlockHeight, etcBlockHeight, dogeBlockHeight, trxBlockHeight, filBlockHeight, zecBlockHeight] =
    await Promise.all([
      fetchETHBlockHeight().catch((e) => {
        console.warn(e)
        return -1
      }),
      etcService.getBlockNumber().catch((e) => {
        console.warn(e)
        return -1
      }),
      fetchDogeBlockHeight().catch((e) => {
        console.warn(e)
        return -1
      }),
      fetchTronBlockHeight().catch((e) => {
        console.warn(e)
        return -1
      }),
      filecoinRpc.getHeight().catch((e) => {
        console.warn(e)
        return -1
      }),
      zcashRpc.getHeight().catch((e) => {
        console.warn(e)
        return -1
      }),
    ])

  const txArr = await fetchSignedTx()

  const newTxArr = txArr.map((item) => {
    let curBlockHeight = -1
    switch (item.coinType) {
      case 'DOGE':
        curBlockHeight = dogeBlockHeight
        break
      case 'USDTTRC20':
      case 'USDCTRC20':
      case 'TRX':
        curBlockHeight = trxBlockHeight
        break
      case 'FIL':
        curBlockHeight = filBlockHeight
        break
      case 'ZEC':
        curBlockHeight = zecBlockHeight
        break
      case 'ETC':
        curBlockHeight = etcBlockHeight
        break
      default:
        curBlockHeight = ethBlockHeight
        break
    }
    return { ...item, curBlockHeight }
  })

  return { txArr: newTxArr }
}

const Withdraw = (props) => {
  const { data } = props
  return [
    <tr key={'withdraw'}>
      <th>id(withdraw)</th>
      <th>value</th>
      <th>coinType</th>
      <th>sendState</th>
      <th>blockHeight</th>
      <th>txHash</th>
      <th>to</th>
      <th>walletType</th>
    </tr>,
    data.map((item, index) => WithdrawRow(item, index)),
  ]
}
const Commission = (props) => {
  const { data } = props
  return [
    <tr key={'commission'}>
      <th>id(commission)</th>
      <th>value</th>
      <th>coinType</th>
      <th>sendState</th>
      <th>blockHeight</th>
      <th>txHash</th>
      <th>to</th>
    </tr>,
    data.map((item, index) => WithdrawRow(item, index)),
  ]
}
const WithdrawRow = (item, index) => {
  const { sendToAddress, amount, id, coinType, sendState, blockHeight, txHash, curBlockHeight, walletType } = item
  const confirmation = blockHeight ? curBlockHeight - blockHeight : '未确认'
  let blockHeightStr = `${blockHeight}(${confirmation})`
  return (
    <tr key={id}>
      <td>{id}</td>
      <td>{format(amount)}</td>
      <td>{coinType}</td>
      <td>{sendState}</td>
      <td>{blockHeightStr}</td>
      <td>{txHash}</td>
      <td>{sendToAddress}</td>
      <td>{walletType}</td>
    </tr>
  )
}
const Deposit = (props) => {
  const { data } = props
  return [
    <tr key={'Deposit'}>
      <th>id(deposit)</th>
      <th>value</th>
      <th>coinType</th>
      <th>sendState</th>
      <th>blockHeight</th>
      <th>txHash</th>
      <th>collectAddr</th>
      <th>to</th>
    </tr>,
    data.map((item, index) => DepositRow(item, index)),
  ]
}

const DepositRow = (item, index) => {
  const { id, collectAddr, sendToAddress, amount, sendState, blockHeight, txHash, coinType, curBlockHeight } = item
  const confirmation = blockHeight ? curBlockHeight - blockHeight : '未确认'
  let blockHeightStr = `${blockHeight}(${confirmation})`
  return (
    <tr key={id}>
      <td>{id}</td>
      <td>{format(amount)}</td>
      <td>{coinType}</td>
      <td>{sendState}</td>
      <td>{blockHeightStr}</td>
      <td>{txHash}</td>
      <td>{collectAddr}</td>
      <td>{sendToAddress}</td>
    </tr>
  )
}

class TxSendList extends Component {
  state = {
    txArr: [],
  }
  timer = 1

  componentDidMount() {
    this.refreshTx(false)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this.timer = 0
  }

  refreshTx = async (loop = false) => {
    try {
      const res = await tasker()
      console.log('refresh tx res %o', res)
      this.setState(res)
    } catch (error) {
      console.error('signed list task error %o', error)
    }
    if (loop && this.timer) {
      this.timer = setTimeout(() => this.refreshTx(true), 5 * 1000)
    }
  }

  _onIdClick = async (e) => {
    const element = e.target
    if (element.getAttribute('mark')) {
      const item = this.state.txArr.filter((item) => item.id === parseInt(e.target.innerText))[0]
      console.log('item is ', item)

      if (
        (item.sendType === SEND_TYPE_WITHRAW || item.sendType === SEND_TYPE_COMMISSION) &&
        item.sendState === 'BROADCASTED'
      ) {
        const priceInWei = await fetchGasPriceWei()
        let priceInGwei = new BigNumber(priceInWei).div(1e9)

        priceInGwei = prompt('确认 priceInGwei?', priceInGwei)
        if (priceInGwei === null) {
          console.log('warn return due to priceInGwei', priceInGwei)
          return
        }
        console.log('mPriceInGwei is ', priceInGwei)
        await repackage(item.id, priceInGwei)
        alert('成功')
      }
    }
  }

  render() {
    const { txArr } = this.state
    // console.log('sign list txArr is ', this.state.txArr)
    const depositArr = txArr.filter(({ sendType }) => sendType === SEND_TYPE_COLLECT)
    const withdrawArr = txArr.filter(({ sendType }) => sendType === SEND_TYPE_WITHRAW)
    const commissionArr = txArr.filter(({ sendType }) => sendType === SEND_TYPE_COMMISSION)
    return (
      <div>
        <div className={'flex'}>
          <h2 className={'text-lg'}>Signed Tx</h2>
          <button onClick={() => this.refreshTx()} className={'btn btn-sm ml-4'}>
            Refresh
          </button>
        </div>
        <table className={'table table-zebra table-compact w-full'}>
          <tbody onClick={this._onIdClick}>
            <Deposit data={depositArr} />
            <Withdraw data={withdrawArr} />
            <Commission data={commissionArr} />
          </tbody>
        </table>
      </div>
    )
  }
}

export default TxSendList
