import React, { Component } from 'react'
import { applyCollect, applyCommission, fetchBalanceList } from '../services/api'
import { getTokens, emitter } from '../services/token_manager'
import BalanceRow from './BalanceRow'
import Tron from '../services/tron'

export default class BalanceList extends Component {
  state = {
    balanceList: [],
    keys: {},
    selectKey: '',
    minValue: '',
    maxValue: '',
  }
  timer = true
  refreshList = async (loop = false) => {
    try {
      const { selectKey: key, minValue, maxValue } = this.state
      const balanceList = await fetchBalanceList(key, minValue, maxValue)
      this.setState({ balanceList })
    } catch (error) {
      console.warn('balance list task error', error)
    }
    if (loop && this.timer) {
      this.timer = setTimeout(() => this.refreshList(true), 5 * 1000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this.timer = false
  }

  componentDidMount() {
    this.refreshList(false)

    const initKeys = {
      ETH: false,
      ETC: false,
      USDT: false,
      USDTTRC20: false,
      USDCTRC20: false,
      FIL: false,
      ZEC: false,
      LTC: false,
      DOGE: false,
    }
    emitter.onChange = (x) => {
      Object.keys(x).forEach((item) => {
        initKeys[item] = false
      })
      this.setState({ keys: { ...initKeys } })
    }
    const initToken = getTokens()
    Object.keys(initToken).forEach((item) => {
      initKeys[item] = false
    })
    this.setState({ keys: { ...initKeys } })
  }

  // 存链上手续费
  applyCommission = (address, coinType) => {
    let amount
    if (coinType === 'USDTTRC20' || coinType === 'USDCTRC20') {
      amount = prompt('请输入TRX手续费数量（默认10个）', '10')
    }
    applyCommission(address, coinType, amount).then((res) => {
      console.log('applyCommission res is ', res)
      this.refreshList()
      this.props.refreshUnsigned()
    })
  }
  applyCollect = (address, coinType, defaultAmount) => {
    if (coinType === 'FIL') {
      // 不能预估手续费，留0.1做手续费
      defaultAmount = `${(parseFloat(defaultAmount) * 100 - 1) / 100}`
    }
    const amount = prompt('请输入收集数量', defaultAmount)
    if (Number.isNaN(parseFloat(amount))) return
    applyCollect(address, coinType, amount).then((res) => {
      console.log('applyCollect res is ', res)
      this.refreshList()
      this.props.refreshUnsigned()
    })
  }

  freezeBalance = async (address, coinType) => {
    const response = await Tron.freezeBalance(address, Tron.TRON_FREEZE.ENERGY)
    console.log('freezeBalance response', response)
  }

  updateCheckBox = (coin) => {
    this.setState({
      selectKey: coin,
    })
  }
  renderCheckBtns = () => {
    const keys = Object.keys(this.state.keys || {})
    return (
      <div className={'form-control flex-row'}>
        {keys.map((item) => {
          return (
            <label key={item} className={'label cursor-pointer'}>
              <input
                name={'balanceKey'}
                value={item}
                type="radio"
                onChange={() => this.updateCheckBox(item)}
                className="radio"
              />
              <span className="label-text">{item}</span>
            </label>
          )
        })}
      </div>
    )
  }
  updateValue = (value, type) => {
    this.setState({
      [type]: parseFloat(value) || 0,
    })
  }

  render() {
    const { balanceList } = this.state
    console.log('balanceList', balanceList)
    const newArr = balanceList.map((item) => {
      return {
        ...item,
        applyCollect: this.applyCollect,
        applyCommission: this.applyCommission,
        freezeBalance: this.freezeBalance,
      }
    })
    return (
      <div>
        <div className="scroll2" style={styles.container}>
          <h2 className={'text-lg'}>BalanceList</h2>
          {this.renderCheckBtns()}
          <br />
          minValue:
          <input
            type="text"
            className="input input-bordered input-sm"
            value={this.state.minValue}
            onChange={(e) => this.updateValue(e.target.value, 'minValue')}
          />
          maxValue:
          <input
            type="text"
            value={this.state.maxValue}
            onChange={(e) => this.updateValue(e.target.value, 'maxValue')}
            className="input input-bordered input-sm"
          />
          <button onClick={(e) => this.refreshList()} className={'btn btn-sm'}>
            Refresh
          </button>
          <table className={'table table-zebra table-compact w-full'}>
            <tbody>
              <tr>
                <th>address</th>
                <th>balance</th>
                <th>coinType</th>
                <th>查询余额</th>
                <th>转入手续费</th>
                <th>转出余额</th>
              </tr>
              {newArr.map((item, index) => (
                <BalanceRow key={item.address} item={item} index={index} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    maxHeight: '30rem',
    overflow: 'auto',
    overflowX: 'visible',
    paddingRight: '1rem',
  },
}
