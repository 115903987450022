import React, { Component } from 'react'
import { setWallet } from '../services/api'
import { getDepositXPubKey, getLegacyAddress } from '../services/address'
import { BTC_MAIN_WALLET_FULL_PATH, USDT_USER_WALLET_BASE_PATH, USDT_MAIN_WALLET_KEY, USDT_XPUB_KEY } from '../config'

export default class UsdtWallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      walletId: null,
      sendingAddr: localStorage.getItem(USDT_MAIN_WALLET_KEY),
      xpub: localStorage.getItem(USDT_XPUB_KEY),
      show: false,
    }
  }

  onClickGetSendingAddress = () => {
    getLegacyAddress(BTC_MAIN_WALLET_FULL_PATH, (err, r) => {
      if (r) {
        const sendingAddr = r
        this.setState({ sendingAddr })
        localStorage.setItem(USDT_MAIN_WALLET_KEY, sendingAddr)
      }
    })
  }

  onClickGetXpub = () => {
    getDepositXPubKey(USDT_USER_WALLET_BASE_PATH, (e, r) => {
      if (r) {
        this.setState({ xpub: r })
        localStorage.setItem(USDT_XPUB_KEY, r)
      }
    })
  }

  onClickClearStorage = () => {
    localStorage.removeItem(USDT_MAIN_WALLET_KEY)
    localStorage.removeItem(USDT_XPUB_KEY)
    window.location.reload()
  }

  onClickSaveWallet = () => {
    setWallet({
      coin: 'USDT',
      coinType: 'USDT',
      xpub: localStorage.getItem(USDT_XPUB_KEY),
      xpubPath: USDT_USER_WALLET_BASE_PATH,
      sendingAddr: localStorage.getItem(USDT_MAIN_WALLET_KEY),
      testnet: true,
    }).then((r) => this.setState({ walletId: r.id }))
  }

  _toggle = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  componentDidMount() {}

  render() {
    if (!this.state.show) {
      return <h2 onClick={this._toggle}>USDT Wallet (click to show)</h2>
    }
    return (
      <div>
        <h2 onClick={this._toggle}>USDT Wallet (click to hide)</h2>
        <div>
          <p>wallet id: {this.state.walletId || 'wallet not saved'}</p>
          <button onClick={this.onClickClearStorage} className={'btn btn-sm'}>
            clear storage cache
          </button>
          <button onClick={this.onClickSaveWallet} className={'btn btn-sm'}>
            save wallet
          </button>
        </div>
        <div>
          <p>wallet xpub: {this.state.xpub}</p>
          <button onClick={this.onClickGetXpub} className={'btn btn-sm'}>
            get xpub key
          </button>
        </div>
        <div>
          <p>wallet sending addr: {this.state.sendingAddr}</p>
          <button onClick={this.onClickGetSendingAddress} className={'btn btn-sm'}>
            get sending addr
          </button>
        </div>
      </div>
    )
  }
}
