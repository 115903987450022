/**
 * https://github.com/LedgerHQ/ledgerjs
 */
import Transport from '@ledgerhq/hw-transport-webhid'

const LEDGER_VENDOR_ID = 0x2c97

export const isConnected = async () => {
  const devices = await navigator.hid.getDevices()
  return !!devices.find((device) => device.vendorId === LEDGER_VENDOR_ID)
}

export const connect = async () => {
  const devices = await navigator.hid.requestDevice({ filters: [{ vendorId: LEDGER_VENDOR_ID }] })
  if (devices.length === 0) {
    alert(`设备未连接`)
  } else {
    window.location.reload()
  }
}

let _transport

export const getTransport = async () => {
  if (!(await isConnected())) {
    _transport = null
  }
  if (_transport) {
    return Promise.resolve(_transport)
  } else {
    try {
      _transport = await Transport.create()
    } catch (err) {
      alert(`ledger 连接失败: ${err}`)
      throw err
    }
    return _transport
  }
}
