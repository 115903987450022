import BigNumber from 'bignumber.js'
import numeral from 'numeral'

export function formatHex(hex) {
  const str = hex.replace(/^0x/, '')
  // return str
  if (str.length % 2 !== 0) {
    return '0' + str
  } else {
    return str
  }
}

export function paddingAmount(num, length) {
  return (Array(length).join('0') + num).slice(-length)
}

export function estimateTxSizeByByte(inputAmount, outputAmount) {
  const random = Math.floor(Math.random(0, 1) * 10)
  return inputAmount * 180 + outputAmount * 34 + 10 + random
}

export const satoshi2btc = (satoshis) => {
  return new BigNumber(satoshis).dividedBy(new BigNumber(1e8)).toNumber()
}

export function path2AddressN(sendingAddrPath) {
  return sendingAddrPath
    .split('/')
    .slice(1, 6)
    .map(Number.parseFloat)
    .map((item, index) => (index < 3 ? item | 0x80000000 : item))
}

export function convertUtxo2Input(utxoArr, addressN) {
  return utxoArr.map(({ vout: prev_index, txid: prev_hash, path }) => ({
    address_n: addressN ? addressN : path2AddressN(path),
    prev_index,
    prev_hash,
  }))
}

export function format(value) {
  return value > 0 ? numeral(value).format('0,0.[000000000000000000]') : value
}
