import { ethers } from 'ethers'
import { ETC_MAINNET_RPC_URL, ETH_MAINNET_RPC_URL } from '../config'

export class ETHFamilyService {
  static _Chain = {
    MAINNET: {
      rpcUrl: ETH_MAINNET_RPC_URL,
    },
    ETC_MAINNET: {
      rpcUrl: ETC_MAINNET_RPC_URL,
    },
  }

  constructor(coin) {
    this._coin = coin
    this._chain = this._getChain(this._coin)
    this._provider = this._getProvider(this._chain)
  }

  async getBlockNumber() {
    return this._provider.getBlockNumber()
  }

  async getBalance(address) {
    const balance = await this._provider.getBalance(address)
    return ethers.utils.formatEther(balance)
  }

  _getChain(coin) {
    switch (coin) {
      case 'ETH':
        return 'MAINNET'
      case 'ETC':
        return 'ETC_MAINNET'
      default:
        throw Error(`${coin} not supported`)
    }
  }

  _getProvider(chain) {
    const config = ETHFamilyService._Chain[chain]
    if (!config) throw new Error(`Unknown chain ${chain}`)
    return new ethers.providers.JsonRpcProvider(config.rpcUrl)
  }
}

export const etcService = new ETHFamilyService('ETC')
