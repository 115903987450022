import React, { Component } from 'react'
import { fetchExportAddress, fetchXpub, setWallet } from '../services/api'
import {
  ETH_NETWORK,
  TRX_USER_WALLET_BASE_PATH,
  TRX_MAIN_WALLET_FULL_PATH,
  TRX_MAIN_WALLET_KEY,
  TRX_XPUB_KEY,
} from '../config'
import Tron from '../services/tron'

export default class TronWallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      walletId: null,
      sendingAddr: localStorage.getItem(TRX_MAIN_WALLET_KEY),
      xpub: localStorage.getItem(TRX_XPUB_KEY),
      show: false,
    }
  }

  async onClickGetSendingAddress() {
    const { result, error } = await fetchExportAddress(TRX_MAIN_WALLET_FULL_PATH, 'TRX_EXPORT_ADDR')
    if (error) {
      console.error('export trx address :', error)
    } else {
      this.setState({ sendingAddr: result })
      localStorage.setItem(TRX_MAIN_WALLET_KEY, result)
    }
  }

  async onClickFreezeBalance(address, type) {
    console.log('address %s, type %s', address, type)
    const response = await Tron.freezeBalance(address, type)
    console.log('onClickFreezeBalance response', response)
  }

  async onClickUnFreezeBalance(address, type) {
    console.log('address %s, type %s', address, type)
    const response = await Tron.unFreezeBalance(address, type)
    console.log('onClickFreezeBalance response', response)
  }

  async onClickGetXpub() {
    const { result, error } = await fetchXpub('TRX', TRX_USER_WALLET_BASE_PATH)
    if (error) {
      console.error('getXpub trx :', error)
    } else {
      this.setState({ xpub: result })
      localStorage.setItem(TRX_XPUB_KEY, result)
    }
  }

  onClickClearStorage() {
    localStorage.removeItem(TRX_MAIN_WALLET_KEY)
    localStorage.removeItem(TRX_XPUB_KEY)
    window.location.reload()
  }

  onClickSaveWallet() {
    const testnet = ETH_NETWORK !== 'mainnet'
    setWallet({
      coin: 'TRX',
      coinType: 'TRX',
      xpub: localStorage.getItem(TRX_XPUB_KEY),
      xpubPath: TRX_USER_WALLET_BASE_PATH,
      sendingAddr: localStorage.getItem(TRX_MAIN_WALLET_KEY),
      testnet,
    }).then((r) => this.setState({ walletId: r.id }))
  }

  _toggle = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  render() {
    if (!this.state.show) {
      return <h2 onClick={this._toggle}>TRX Wallet (click to show)</h2>
    }
    return (
      <div>
        <h2 onClick={this._toggle}>TRX Wallet (click to hide)</h2>
        <div>
          <p>wallet id: {this.state.walletId || 'wallet not saved'}</p>
          <button onClick={() => this.onClickClearStorage()} className={'btn btn-sm'}>
            clear storage cache
          </button>
          <button onClick={() => this.onClickSaveWallet()} className={'btn btn-sm'}>
            save wallet
          </button>
        </div>
        <div>
          <p>wallet xpub: {this.state.xpub}</p>
          <button onClick={() => this.onClickGetXpub()} className={'btn btn-sm'}>
            get xpub key
          </button>
        </div>
        <div>
          <p>wallet sending addr: {this.state.sendingAddr}</p>
          <button onClick={() => this.onClickGetSendingAddress()} className={'btn btn-sm'}>
            get sending addr
          </button>
        </div>
        <div>
          <p>冻结TRX获取能量: {this.state.sendingAddr}</p>
          <button
            onClick={() => this.onClickFreezeBalance(this.state.sendingAddr, Tron.TRON_FREEZE.ENERGY)}
            className={'btn btn-sm'}
          >
            freezeBalance for ENERGY
          </button>
        </div>
        <div>
          <p>冻结TRX获取带宽: {this.state.sendingAddr}</p>
          <button
            onClick={() => this.onClickFreezeBalance(this.state.sendingAddr, Tron.TRON_FREEZE.BANDWIDTH)}
            className={'btn btn-sm'}
          >
            freezeBalance for BANDWIDTH
          </button>
        </div>
        <div>
          <p>解冻能量获取TRX: {this.state.sendingAddr}</p>
          <button
            onClick={() => this.onClickUnFreezeBalance(this.state.sendingAddr, Tron.TRON_FREEZE.ENERGY)}
            className={'btn btn-sm'}
          >
            unfreezeBalance with ENERGY
          </button>
        </div>
        <div>
          <p>解冻带宽获取TRX: {this.state.sendingAddr}</p>
          <button
            onClick={() => this.onClickUnFreezeBalance(this.state.sendingAddr, Tron.TRON_FREEZE.BANDWIDTH)}
            className={'btn btn-sm'}
          >
            unfreezeBalance with BANDWIDTH
          </button>
        </div>
      </div>
    )
  }
}
