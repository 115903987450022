import React, { Component } from 'react'
import { setWallet } from '../services/api'
import { getDepositXPubKey } from '../services/address'
import { LTC_XPUB_KEY, LTC_USER_WALLET_BASE_PATH } from '../config'

export default class UsdtWallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      walletId: null,
      xpub: localStorage.getItem(LTC_XPUB_KEY),
      show: false,
    }
  }

  onClickGetXpub = () => {
    getDepositXPubKey(LTC_USER_WALLET_BASE_PATH, (e, r) => {
      if (r) {
        this.setState({ xpub: r })
        localStorage.setItem(LTC_XPUB_KEY, r)
      }
    })
  }

  onClickClearStorage = () => {
    localStorage.removeItem(LTC_XPUB_KEY)
    window.location.reload()
  }

  onClickSaveWallet = async () => {
    const r = await setWallet({
      coin: 'LTC',
      coinType: 'LTC',
      xpub: localStorage.getItem(LTC_XPUB_KEY),
      xpubPath: LTC_USER_WALLET_BASE_PATH,
      testnet: true,
      sendingAddr: '',
    })
    this.setState({ walletId: r.id })
    alert('保存成功')
  }

  _toggle = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  componentDidMount() {}

  render() {
    if (!this.state.show) {
      return <h2 onClick={this._toggle}>LTC Wallet (click to show)</h2>
    }
    return (
      <div>
        <h2 onClick={this._toggle}>LTC Wallet (click to hide)</h2>
        <div>
          <p>wallet id: {this.state.walletId || 'wallet not saved'}</p>
          <button onClick={this.onClickClearStorage} className={'btn btn-sm'}>
            clear storage cache
          </button>
          <button onClick={this.onClickSaveWallet} className={'btn btn-sm'}>
            save wallet
          </button>
        </div>
        <div>
          <p>wallet xpub: {this.state.xpub}</p>
          <button onClick={this.onClickGetXpub} className={'btn btn-sm'}>
            get xpub key
          </button>
        </div>
      </div>
    )
  }
}
